/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
  // max-width: 1280px;
  position: relative;
}

.app-wrapper {
  padding: 30px;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding: 20px;
  }
}

.content-row-wrap {
  @include display-flex(flex, row, wrap);
  @include align-items(start);
}
.content-col-wrap {
  @include display-flex(flex, col, wrap);
  @include align-items(start);
}